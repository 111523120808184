import {type ShareSalesChannelType} from "@atg-shop-shared/types/share.enum";
import {type FetchAction} from "@atg-shared/fetch-types";
import type {ShopShare} from "@atg-shop-shared/types/shopShare";
import type {State} from "./index";

export const SHARE_ROUND_ACTIVATE_FLOW = "shareRound/SHARE_ROUND_ACTIVATE_FLOW";

export const SHARE_ROUND_ACTIVATE_REQUEST = "shareRound/ACTIVATE_REQUEST";
export const SHARE_ROUND_ACTIVATE_RESPONSE = "shareRound/ACTIVATE_RESPONSE";
export const SHARE_ROUND_ACTIVATE_RESET = "shareRound/ACTIVATE_RESET";

export const SHARE_ROUND_EDIT_REQUEST = "shareRound/EDIT_REQUEST";
export const SHARE_ROUND_EDIT_RESPONSE = "shareRound/EDIT_RESPONSE";
export const SHARE_ROUND_EDIT_RESET = "shareRound/EDIT_RESET";

export const SHARE_ROUND_EDIT_DEFAULT_REQUEST = "shareRound/EDIT_DEFAULT_REQUEST";
export const SHARE_ROUND_EDIT_DEFAULT_RESPONSE = "shareRound/EDIT_DEFAULT_RESPONSE";
export const SHARE_ROUND_EDIT_DEFAULT_RESET = "shareRound/EDIT_DEFAULT_RESET";

export const SHARE_SELL_OPEN_REQUEST = "shopShare/SHARE_SELL_OPEN_REQUEST";
export const SHARE_SELL_OPEN_RESPONSE = "shopShare/SHARE_SELL_OPEN_RESPONSE";

export const SHARE_SELL_CLOSE_REQUEST = "shopShare/SHARE_SELL_CLOSE_REQUEST";
export const SHARE_SELL_CLOSE_RESPONSE = "shopShare/SHARE_SELL_CLOSE_RESPONSE";

type ShareRoundContext = {
    onSuccess: () => void;
    openRoundForSaleAfterEdit?: boolean;
    autoActivate?: boolean;
    autoActivateDays?: number;
};

export type ShareRoundActivatePayload = {
    commission: number;
    sharesForSale: number;
    salesChannel: ShareSalesChannelType;
    shareCost: number;
    systemSize: number;
    roundDescription: string;
    onlyVx: boolean;
    autoActivate: boolean;
    autoActivateDays: number;
    valuesDefault: boolean;
};

export type ShareRoundActivateFlowAction = {
    type: typeof SHARE_ROUND_ACTIVATE_FLOW;
    payload: ShareRoundActivatePayload;
    onSuccess: () => void;
    shareId: string;
};

export type ShareRoundEditDefaultPayload = {
    shareCost: number;
    commission: number;
    systemSize: number;
    sharesForSale: number;
    roundDescription: string;
    onlyVx: boolean;
    salesChannel?: "ALL" | "ONLINE" | "TERMINAL";
    autoActivate: boolean;
    autoActivateDays: number;
};

export type ShareRoundEditPayload = ShareRoundEditDefaultPayload & {
    valuesDefault: boolean;
};

export type ShareRoundActivateResetAction = {
    type: typeof SHARE_ROUND_ACTIVATE_RESET;
};

export type ShareRoundEditResetAction = {
    type: typeof SHARE_ROUND_EDIT_RESET;
};

export type ShareRoundActivateAction = FetchAction<
    typeof SHARE_ROUND_ACTIVATE_REQUEST,
    typeof SHARE_ROUND_ACTIVATE_RESPONSE,
    ShopShare,
    State
>;

export type ShareRoundEditAction = FetchAction<
    typeof SHARE_ROUND_EDIT_REQUEST,
    typeof SHARE_ROUND_EDIT_RESPONSE,
    ShopShare,
    State,
    ShareRoundContext
>;

export type ShareRoundEditDefaultAction = FetchAction<
    typeof SHARE_ROUND_EDIT_DEFAULT_REQUEST,
    typeof SHARE_ROUND_EDIT_DEFAULT_RESPONSE,
    ShopShare,
    State,
    ShareRoundContext
>;

export type ShareSellOpenAction = FetchAction<
    typeof SHARE_SELL_OPEN_REQUEST,
    typeof SHARE_SELL_OPEN_RESPONSE,
    ShopShare,
    State
>;

export type ShareSellCloseAction = FetchAction<
    typeof SHARE_SELL_CLOSE_REQUEST,
    typeof SHARE_SELL_CLOSE_RESPONSE,
    ShopShare,
    State
>;

export type ShareRoundAction =
    | ShareRoundActivateFlowAction
    | ShareRoundActivateAction
    | ShareRoundActivateResetAction
    | ShareRoundEditAction
    | ShareRoundEditResetAction
    | ShareSellOpenAction
    | ShareSellCloseAction;
