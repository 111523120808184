import {pickBy} from "lodash";
import {omit} from "lodash/fp";
import {type ToastWithId} from "./toast";
import {
    SHOW_TOAST,
    HIDE_TOAST,
    HIDE_TOASTS,
    CLEAR_TOASTS,
    type ToastAction,
} from "./toastActions";

type ToastsState = Record<string, ToastWithId>;

export type GlobalToastState = {
    toasts: ToastsState;
};

const toasts = (state: ToastsState = {}, action: ToastAction): ToastsState => {
    switch (action.type) {
        case SHOW_TOAST: {
            const {toast} = action.payload;
            return {
                ...state,
                [toast.id]: toast,
            };
        }
        case HIDE_TOAST: {
            const {toast} = action.payload;
            return omit([toast.id], state);
        }
        case HIDE_TOASTS: {
            const toastIds = action.payload.toasts.map(({id}) => id) ?? [];
            return omit([...toastIds], state);
        }
        case CLEAR_TOASTS: {
            const {excludeReasons} = action.payload;
            if (excludeReasons) {
                return pickBy(
                    state,
                    (value) => value.reason && excludeReasons.includes(value.reason),
                );
            }
            return {};
        }
        default:
            return state;
    }
};

export default toasts;
