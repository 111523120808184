import type {Action} from "redux";
import {runLazySaga} from "@atg-shared/lazy-store";
import type {LazyStore} from "@atg-shared/lazy-store";
import {fetchSaga} from "@atg-shared/fetch-redux";
import toastSaga from "atg-ui-toast/domain/toastSaga";

export default function rootSaga(store: LazyStore<unknown, Action>) {
    runLazySaga(store, fetchSaga);
    runLazySaga(store, toastSaga);
}
